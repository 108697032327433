import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backToTop: {
      position: 'fixed',
      bottom: theme.spacing(16),
      right: theme.spacing(2),
    },
    backToTopFab: {
      backgroundColor: CONF.css.blue,
      color: CONF.css.white,
    },
    table: {
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    edit: {
      // top: theme.spacing(12),
      // left: theme.spacing(15),
      width: theme.spacing(40),
      height: theme.spacing(8),
      // backgroundColor: '#f77b18',
    },
  })
)
