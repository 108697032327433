import * as React from 'react'
import Fab from '@material-ui/core/Fab'
import ChatIcon from '@material-ui/icons/Chat'
import { useStyles } from './style'
import Button from '@material-ui/core/Button'
import { ClickAwayListener, FormControl, FormControlLabel, FormLabel, Paper, Radio, TextField } from '@material-ui/core'
import { FeedBackState } from './state'
import RadioGroup from '@material-ui/core/RadioGroup'
import { useTranslation } from 'react-i18next'
import { useState } from '@hookstate/core'
import { LayoutState } from '../state'

export default function FeedBack() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [category, setCategory] = React.useState('BUG_REPORT')
  const [content, setContent] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const snackState = useState(LayoutState.snack)

  const handleClick = () => {
    setOpen((prev) => !prev)
  }
  const handleClickAway = () => {
    setOpen(false)
  }

  const handleSendFeedBack = () => {
    FeedBackState.postFeedBack(category, content)()
    setOpen(false)
    setContent('')
    snackState.set({ text: 'Send FeedBack successfully', variant: 'success' })
  }

  return (
    <div className={classes.backToTop}>
      <Fab className={classes.backToTopFab} size="medium" onClick={handleClick}>
        <ChatIcon />
      </Fab>
      <div>
        {open ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper className={classes.feedbackMenu} elevation={10}>
              {/*<Typography className={classes.feedbackTitle}>Feed back</Typography>*/}

              <FormControl className={classes.feedbackSelect} component="fieldset">
                <FormLabel component="legend">{t('Feed back')}</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={category}
                  onChange={(e) => setCategory(e.target.value as string)}
                >
                  <FormControlLabel value="BUG_REPORT" control={<Radio />} label={t('Bug report')} />
                  <FormControlLabel value="OTHER" control={<Radio />} label={t('Other')} />
                </RadioGroup>
              </FormControl>

              <div>
                <TextField
                  className={classes.feedbackText}
                  id="outlined-multiline-static"
                  label={t('Content')}
                  multiline
                  rows={4}
                  // placeholder={t('Your opinion')}
                  variant="outlined"
                  value={content}
                  onChange={(event) => {
                    setContent(event.target.value)
                  }}
                />
              </div>
              <div>
                <Button className={classes.feedbackButton} variant={'contained'} onClick={handleSendFeedBack}>
                  {t('Send')}
                </Button>
              </div>
            </Paper>
          </ClickAwayListener>
        ) : null}
      </div>
    </div>
  )
}
