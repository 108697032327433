/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import BackToTop from './BackToTop'
import FeedBack from './FeedBack'
import SnackBarNotification from './SnackBar'
import Tutorial from './Tutorial'
import Header from './Header'

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <CssBaseline />
      <div id="back-to-top-anchor" />
      <Header />
      <Container style={{ minHeight: 1000 }}>
        <main>{children}</main>
      </Container>

      <SnackBarNotification />

      <BackToTop />
      <FeedBack />
      <Tutorial />
      {/*<Footer />*/}
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
