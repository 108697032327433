import { postAxios } from '../../../utils/parse'

const URL = {
  postFeedBack: '/feedbacks/createCustom',
}

export const FeedBackState = {
  content: '',

  postFeedBack(category: string, content: string) {
    return async () => {
      const response = await postAxios(URL.postFeedBack, { category, content })
    }
  },
}
