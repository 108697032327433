import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backToTop: {
      position: 'fixed',
      bottom: theme.spacing(9),
      right: theme.spacing(2),
    },
    backToTopFab: {
      backgroundColor: CONF.css.blue,
      color: CONF.css.white,
    },
    feedbackMenu: {
      padding: '1.5rem 0.5rem 1rem',
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    feedbackTitle: {
      margin: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    feedbackSelect: {
      marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // width: '92%'
    },
    feedbackText: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    feedbackButton: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(14),
      marginRight: theme.spacing(1),
    },
  })
)
