import * as React from 'react'
import Fab from '@material-ui/core/Fab'
import ChatIcon from '@material-ui/icons/Chat'
import { useStyles } from './style'
import Button from '@material-ui/core/Button'
import { ClickAwayListener, FormControl, FormControlLabel, FormLabel, Paper, Radio, TextField } from '@material-ui/core'
import { FeedBackState, TutorialState } from './state'
import RadioGroup from '@material-ui/core/RadioGroup'
import { useTranslation } from 'react-i18next'
import { useState } from '@hookstate/core'
import { LayoutState } from '../state'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ReactPlayer from 'react-player'
import CONF from '../../../config'
import Typography from '@material-ui/core/Typography'
import { LanguageState } from '../Header/state'

export default function Tutorial() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [category, setCategory] = React.useState('BUG_REPORT')
  const [content, setContent] = React.useState('')
  // const [open, setOpen] = React.useState(true)
  const openState = useState(TutorialState.open)
  const snackState = useState(LayoutState.snack)
  const languageState = useState(LanguageState.language)

  const handleClick = () => {
    openState.set((prev) => !prev)
  }
  const handleClickAway = () => {
    openState.set(false)
  }

  const handleSendFeedBack = () => {
    FeedBackState.postFeedBack(category, content)()
    openState.set(false)
    setContent('')
    snackState.set({ text: 'Send FeedBack successfully', variant: 'success' })
  }

  return (
    <div className={classes.backToTop}>
      <Fab className={classes.backToTopFab} size="medium" onClick={handleClick}>
        <HelpOutlineIcon />
      </Fab>
      <div>
        {openState.get() ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper className={classes.feedbackMenu} elevation={10}>
              <Typography className={classes.feedbackTitle}>{t('Introduction')}</Typography>
              <ReactPlayer
                controls
                width={'300px'}
                height={'165px'}
                url={languageState.get() === 'vi' ? CONF.tutorial.howToVi : CONF.tutorial.howToEn}
              />
            </Paper>
          </ClickAwayListener>
        ) : null}
      </div>
    </div>
  )
}
