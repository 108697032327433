import { postAxios } from '../../../utils/parse'
import { createState } from '@hookstate/core'

const URL = {
  postFeedBack: '/feedbacks/createCustom',
}

export const TutorialState = {
  open: createState<boolean>(true),

}

export const FeedBackState = {
  content: '',

  postFeedBack(category: string, content: string) {
    return async () => {
      const response = await postAxios(URL.postFeedBack, { category, content })
    }
  },
}
